export const loginURL =
  "https://wordpress.trustysystem.com//wp-json/custom-login/v1/login";

export const logoutURL =
  "https://wordpress.trustysystem.com//wp-json/custom-login/v1/logout";

export const url = "https://trustyuae.com/wp-json/";
export const API_URL = "https://trustyuae.com/";

// export const url ="https://ghostwhite-guanaco-836757.hostingersite.com/wp-json/";
// export const API_URL = "https://ghostwhite-guanaco-836757.hostingersite.com/";

export const url = "https://trustyuae.com/wp-json/";
export const API_URL = "https://trustyuae.com/";

// export const url =
//   "https://ghostwhite-guanaco-836757.hostingersite.com/wp-json/";
// export const API_URL = "https://ghostwhite-guanaco-836757.hostingersite.com/";

export const username = "ck_176cdf1ee0c4ccb0376ffa22baf84c096d5a155a";
export const password = "ck_176cdf1ee0c4ccb0376ffa22baf84c096d5a155a";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const CLEAR_STORE = "CLEAR_STORE";

export const USER_LOGOUT_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGIN_FAIL";

export const GET_All_FACTORY_REQUEST = "All_FACTORY_REQUEST";
export const GET_All_FACTORY_SUCCESS = "All_FACTORY_SUCCESS";
export const GET_All_FACTORY_FAIL = "All_FACTORY_FAIL";

export const ADD_MESSAGE_REQUEST = "ADD_MESSAGE_REQUEST";
export const ADD_MESSAGE_SUCCESS = "ADD_MESSAGE_SUCCESS";
export const ADD_MESSAGE_FAIL = "ADD_MESSAGE_FAIL";

export const EDIT_FACTORY_REQUEST = "EDIT_FACTORY_REQUEST";
export const EDIT_FACTORY_SUCCESS = "EDIT_FACTORY_SUCCESS";
export const EDIT_FACTORY_FAIL = "EDIT_FACTORY_SUCCESS";

export const ADD_FACTORY_REQUEST = "ADD_FACTORY_REQUEST";
export const ADD_FACTORY_SUCCESS = "ADD_FACTORY_SUCCESS";
export const ADD_FACTORY_FAIL = "ADD_FACTORY_FAIL";

export const GET_ORDER_SYSTEM_REQUEST = "GET_ORDER_SYSTEM_REQUEST";
export const GET_ORDER_SYSTEM_SUCCESS = "GET_ORDER_SYSTEM_SUCCESS";
export const GET_ORDER_SYSTEM_FAIL = "GET_ORDER_SYSTEM_FAIL";

export const GET_COMPLETED_ORDER_SYSTEM_REQUEST =
  "GET_COMPLETED_ORDER_SYSTEM_REQUEST";
export const GET_COMPLETED_ORDER_SYSTEM_SUCCESS =
  "GET_COMPLETED_ORDER_SYSTEM_SUCCESS";
export const GET_COMPLETED_ORDER_SYSTEM_FAIL =
  "GET_COMPLETED_ORDER_SYSTEM_FAIL";

export const GET_COMPLETED_ORDER_DETAILS_SYSTEM_REQUEST =
  "GET_COMPLETED_ORDER_DETAILS_SYSTEM_REQUEST";
export const GET_COMPLETED_ORDER_DETAILS_SYSTEM_SUCCESS =
  "GET_COMPLETED_ORDER_DETAILS_SYSTEM_SUCCESS";
export const GET_COMPLETED_ORDER_DETAILS_SYSTEM_FAIL =
  "GET_COMPLETED_ORDER_DETAILS_SYSTEM_FAIL";

export const GET_ON_HOLD_ORDER_DETAILS_SYSTEM_REQUEST =
  "GET_ON_HOLD_ORDER_DETAILS_SYSTEM_REQUEST";
export const GET_ON_HOLD_ORDER_DETAILS_SYSTEM_SUCCESS =
  "GET_ON_HOLD_ORDER_DETAILS_SYSTEM_SUCCESS";
export const GET_ON_HOLD_ORDER_DETAILS_SYSTEM_FAIL =
  "GET_ON_HOLD_ORDER_DETAILS_SYSTEM_FAIL";

export const GET_ORDER_DETAILS_REQUEST = "GET_ORDER_DETAILS_REQUEST";
export const GET_ORDER_DETAILS_SUCCESS = "GET_ORDER_DETAILS_SUCCESS";
export const GET_ORDER_DETAILS_FAIL = "GET_ORDER_DETAILS_FAIL";

export const GET_MISSING_ORDER_SYSTEM_REQUEST = "GET_MISSING_ORDER_SYSTEM_REQUEST";
export const GET_MISSING_ORDER_SYSTEM_SUCCESS = "GET_MISSING_ORDER_SYSTEM_SUCCESS";
export const GET_MISSING_ORDER_SYSTEM_FAIL = "GET_MISSING_ORDER_SYSTEM_FAIL";

export const GET_MISSING_ORDER_DETAILS_REQUEST = "GET_MISSING_ORDER_DETAILS_REQUEST";
export const GET_MISSING_ORDER_DETAILS_SUCCESS = "GET_MISSING_ORDER_DETAILS_SUCCESS";
export const GET_MISSING_ORDER_DETAILS_FAIL = "GET_MISSING_ORDER_DETAILS_FAIL";

export const UPLOAD_ATTACH_FILE_REQUEST = "UPLOAD_ATTACH_FILE_REQUEST";
export const UPLOAD_ATTACH_FILE_SUCCESS = "UPLOAD_ATTACH_FILE_SUCCESS";
export const UPLOAD_ATTACH_FILE_FAIL = "UPLOAD_ATTACH_FILE_FAIL";

export const UPLOAD_OVERALL_ATTACH_FILE_REQUEST =
  "UPLOAD_OVERALL_ATTACH_FILE_REQUEST";
export const UPLOAD_OVERALL_ATTACH_FILE_SUCCESS =
  "UPLOAD_OVERALL_ATTACH_FILE_SUCCESS";
export const UPLOAD_OVERALL_ATTACH_FILE_FAIL =
  "UPLOAD_OVERALL_ATTACH_FILE_FAIL";

export const INSERT_ORDER_PICKUP_REQUEST = "INSERT_ORDER_PICKUP_REQUEST";
export const INSERT_ORDER_PICKUP_SUCCESS = "INSERT_ORDER_PICKUP_SUCCESS";
export const INSERT_ORDER_PICKUP_FAIL = "INSERT_ORDER_PICKUP_FAIL";

export const INSERT_ORDER_PICKUP_CANCEL_REQUEST =
  "INSERT_ORDER_PICKUP_CANCEL_REQUEST";
export const INSERT_ORDER_PICKUP_CANCEL_SUCCESS =
  "INSERT_ORDER_PICKUP_CANCEL_SUCCESS";
export const INSERT_ORDER_PICKUP_CANCEL_FAIL =
  "INSERT_ORDER_PICKUP_CANCEL_FAIL";

export const CUSTOM_ORDER_FINISH_REQUEST = "CUSTOM_ORDER_FINISH_REQUEST";
export const CUSTOM_ORDER_FINISH_SUCCESS = "CUSTOM_ORDER_FINISH_SUCCESS";
export const CUSTOM_ORDER_FINISH_FAIL = "CUSTOM_ORDER_FINISH_FAIL";

export const CUSTOM_ORDER_ON_HOLD_REQUEST = "CUSTOM_ORDER_ON_HOLD_REQUEST";
export const CUSTOM_ORDER_ON_HOLD_SUCCESS = "CUSTOM_ORDER_ON_HOLD_SUCCESS";
export const CUSTOM_ORDER_ON_HOLD_FAIL = "CUSTOM_ORDER_ON_HOLD_FAIL";

export const CUSTOM_ORDER_ON_HOLD_FINISH_REQUEST =
  "CUSTOM_ORDER_ON_HOLD_FINISH_REQUEST";
export const CUSTOM_ORDER_ON_HOLD_FINISH_SUCCESS =
  "CUSTOM_ORDER_ON_HOLD_FINISH_SUCCESS";
export const CUSTOM_ORDER_ON_HOLD_FINISH_FAIL =
  "CUSTOM_ORDER_ON_HOLD_FINISH_FAIL";

export const CUSTOM_ORDER_ON_HOLD_SEND_P2_REQUEST =
  "CUSTOM_ORDER_ON_HOLD_SEND_P2_REQUEST";
export const CUSTOM_ORDER_ON_HOLD_SEND_P2_SUCCESS =
  "CUSTOM_ORDER_ON_HOLD_SEND_P2_SUCCESS";
export const CUSTOM_ORDER_ON_HOLD_SEND_P2_FAIL =
  "CUSTOM_ORDER_ON_HOLD_SEND_P2_FAIL";

export const CUSTOM_ORDER_SEND_CHINA_REQUEST =
  "CUSTOM_ORDER_SEND_CHINA_REQUEST";
export const CUSTOM_ORDER_SEND_CHINA_SUCCESS =
  "CUSTOM_ORDER_SEND_CHINA_SUCCESS";
export const CUSTOM_ORDER_SEND_CHINA_FAIL = "CUSTOM_ORDER_SEND_CHINA_FAIL";

export const CUSTOM_ORDER_SEND_UAE_REQUEST = "CUSTOM_ORDER_SEND_UAE_REQUEST";
export const CUSTOM_ORDER_SEND_UAE_SUCCESS = "CUSTOM_ORDER_SEND_UAE_SUCCESS";
export const CUSTOM_ORDER_SEND_UAE_FAIL = "CUSTOM_ORDER_SEND_UAE_FAIL";

export const CUSTOM_ORDER_PUSH_UAE_REQUEST = "CUSTOM_ORDER_PUSH_UAE_REQUEST";
export const CUSTOM_ORDER_PUSH_UAE_SUCCESS = "CUSTOM_ORDER_PUSH_UAE_SUCCESS";
export const CUSTOM_ORDER_PUSH_UAE_FAIL = "CUSTOM_ORDER_PUSH_UAE_FAIL";

export const CUSTOM_MISSING_ORDER_UPDATE_REQUEST = "CUSTOM_MISSING_ORDER_UPDATE_REQUEST";
export const CUSTOM_MISSING_ORDER_UPDATE_SUCCESS = "CUSTOM_MISSING_ORDER_UPDATE_SUCCESS";
export const CUSTOM_MISSING_ORDER_UPDATE_FAIL = "CUSTOM_MISSING_ORDER_UPDATE_FAIL";

export const GET_PO_DETAILS_REQUEST = "GET_PO_DETAILS_REQUEST";
export const GET_PO_DETAILS_SUCCESS = "GET_PO_DETAILS_SUCCESS";
export const GET_PO_DETAILS_FAIL = "GET_PO_DETAILS_FAIL";

export const GET_MANUAL_OR_SCHEDULED_PO_DETAILS_REQUEST =
  "GET_MANUAL_OR_SCHEDULED_PO_DETAILS_REQUEST";
export const GET_MANUAL_OR_SCHEDULED_PO_DETAILS_SUCCESS =
  "GET_MANUAL_OR_SCHEDULED_PO_DETAILS_SUCCESS";
export const GET_MANUAL_OR_SCHEDULED_PO_DETAILS_FAIL =
  "GET_MANUAL_OR_SCHEDULED_PO_DETAILS_FAIL";

export const GET_PERTICULAR_PO_DETAILS_REQUEST =
  "GET_PERTICULAR_PO_DETAILS_REQUEST";
export const GET_PERTICULAR_PO_DETAILS_SUCCESS =
  "GET_PERTICULAR_PO_DETAILS_SUCCESS";
export const GET_PERTICULAR_PO_DETAILS_FAIL = "GET_PERTICULAR_PO_DETAILS_FAIL";

export const GET_QUANTITY_DETAILS_REQUEST = "GET_QUANTITY_DETAILS_REQUEST";
export const GET_QUANTITY_DETAILS_SUCCESS = "GET_QUANTITY_DETAILS_SUCCESS";
export const GET_QUANTITY_DETAILS_FAIL = "GET_QUANTITY_DETAILS_FAIL";

export const GET_QUANTITY_DETAILS_ON_PO_DETAILS_REQUEST =
  "GET_QUANTITY_DETAILS_ON_PO_DETAILS_REQUEST";
export const GET_QUANTITY_DETAILS_ON_PO_DETAILS_SUCCESS =
  "GET_QUANTITY_DETAILS_ON_PO_DETAILS__SUCCESS";
export const GET_QUANTITY_DETAILS_ON_PO_DETAILS_FAIL =
  "GET_QUANTITY_DETAILS_ON_PO_DETAILS_FAIL";

export const ADD_PO_REQUEST = "ADD_PO_REQUEST";
export const ADD_PO_SUCCESS = "ADD_PO_SUCCESS";
export const ADD_PO_FAIL = "ADD_PO_FAIL";

export const ADD_MANUAL_PO_REQUEST = "ADD_MANUAL_PO_REQUEST";
export const ADD_MANUAL_PO_SUCCESS = "ADD_MANUAL_PO_SUCCESS";
export const ADD_MANUAL_PO_FAIL = "ADD_MANUAL_PO_FAIL";

export const ADD_SCHEDULE_PO_REQUEST = "ADD_SCHEDULE_PO_REQUEST";
export const ADD_SCHEDULE_PO_SUCCESS = "ADD_SCHEDULE_PO_SUCCESS";
export const ADD_SCHEDULE_PO_FAIL = "ADD_SCHEDULE_PO_FAIL";

export const UPDATE_PO_DETAILS_REQUEST = "UPDATE_PO_DETAILS_REQUEST";
export const UPDATE_PO_DETAILS_SUCCESS = "UPDATE_PO_DETAILS_SUCCESS";
export const UPDATE_PO_DETAILS_FAIL = "UPDATE_PO_DETAILS_FAIL";

export const GET_POM_SYSTEM_PRODUCTS_DETAILS_REQUEST =
  "GET_POM_SYSTEM_PRODUCTS_DETAILS_REQUEST";
export const GET_POM_SYSTEM_PRODUCTS_DETAILS_SUCCESS =
  "GET_POM_SYSTEM_PRODUCTS_DETAILS_SUCCESS";
export const GET_POM_SYSTEM_PRODUCTS_DETAILS_FAIL =
  "GET_POM_SYSTEM_PRODUCTS_DETAILS_FAIL";

export const GET_ORDER_NOT_AVAILABLE_REQUEST =
  "GET_ORDER_NOT_AVAILABLE_REQUEST";
export const GET_ORDER_NOT_AVAILABLE_SUCCESS =
  "GET_ORDER_NOT_AVAILABLE_SUCCESS";
export const GET_ORDER_NOT_AVAILABLE_FAIL = "GET_ORDER_NOT_AVAILABLE_FAIL";

export const ADD_ORDER_NOT_AVAILABLE_REQUEST =
  "ADD_ORDER_NOT_AVAILABLE_REQUEST";
export const ADD_ORDER_NOT_AVAILABLE_SUCCESS =
  "ADD_ORDER_NOT_AVAILABLE_SUCCESS";
export const ADD_ORDER_NOT_AVAILABLE_FAIL = "ADD_ORDER_NOT_AVAILABLE_FAIL";

export const UPDATE_ORDER_NOT_AVAILABLE_STATUS_REQUEST =
  "UPDATE_ORDER_NOT_AVAILABLE_STATUS_REQUEST";
export const UPDATE_ORDER_NOT_AVAILABLE_STATUS_SUCCESS =
  "UPDATE_ORDER_NOT_AVAILABLE_STATUS_SUCCESS";
export const UPDATE_ORDER_NOT_AVAILABLE_STATUS_FAIL =
  "UPDATE_ORDER_NOT_AVAILABLE_STATUS_FAIL";

export const ADD_ORDER_NOT_AVAILABLE_REFUND_REQUEST =
  "ADD_ORDER_NOT_AVAILABLE_REFUND_REQUEST";
export const ADD_ORDER_NOT_AVAILABLE_REFUND_SUCCESS =
  "ADD_ORDER_NOT_AVAILABLE_REFUND_SUCCESS";
export const ADD_ORDER_NOT_AVAILABLE_REFUND_FAIL =
  "ADD_ORDER_NOT_AVAILABLE_REFUND_FAIL";

export const GET_PRODUCT_MANUAL_REQUEST = "GET_PRODUCT_MANUAL_REQUEST";
export const GET_PRODUCT_MANUAL_SUCCESS = "GET_PRODUCT_MANUAL_SUCCESS";
export const GET_PRODUCT_MANUAL_FAIL = "GET_PRODUCT_MANUAL_FAIL";

export const ADD_GRN_REQUEST = "ADD_GRN_REQUEST";
export const ADD_GRN_SUCCESS = "ADD_GRN_SUCCESS";
export const ADD_GRN_FAIL = "ADD_GRN_FAIL";

export const GET_GRN_LIST_REQUEST = "GET_GRN_LIST_REQUEST";
export const GET_GRN_LIST_SUCCESS = "GET_GRN_LIST_SUCCESS";
export const GET_GRN_LIST_FAIL = "GET_GRN_LIST_FAIL";

export const GET_GRN_VIEW_REQUEST = "GET_GRN_VIEW_REQUEST";
export const GET_GRN_VIEW_SUCCESS = "GET_GRN_VIEW_SUCCESS";
export const GET_GRN_VIEW_FAIL = "GET_GRN_VIEW_FAIL";

export const GET_PRODUCT_DETAILS_REQUEST = "GET_PRODUCT_DETAILS_REQUEST";
export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS";
export const GET_PRODUCT_DETAILS_FAIL = "GET_PRODUCT_DETAILS_FAIL";

export const GET_PRODUCT_ORDER_DETAILS_REQUEST =
  "GET_PRODUCT_ORDER_DETAILS_REQUEST";
export const GET_PRODUCT_ORDER_DETAILS_SUCCESS =
  "GET_PRODUCT_ORDER_DETAILS_SUCCESS";
export const GET_PRODUCT_ORDER_DETAILS_FAIL = "GET_PRODUCT_ORDER_DETAILS_FAIL";

export const ADD_PRODUCT_ORDER_FOR_PREP_REQUEST =
  "ADD_PRODUCT_ORDER_FOR_PREP_REQUEST";
export const ADD_PRODUCT_ORDER_FOR_PREP_SUCCESS =
  "ADD_PRODUCT_ORDER_FOR_PREP_SUCCESS";
export const ADD_PRODUCT_ORDER_FOR_PREP_FAIL =
  "ADD_PRODUCT_ORDER_FOR_PREP_FAIL";

export const ADD_PRODUCT_ORDER_FOR_STOCK_REQUEST =
  "ADD_PRODUCT_ORDER_FOR_STOCK_REQUEST";
export const ADD_PRODUCT_ORDER_FOR_STOCK_SUCCESS =
  "ADD_PRODUCT_ORDER_FOR_STOCK_SUCCESS";
export const ADD_PRODUCT_ORDER_FOR_STOCK_FAIL =
  "ADD_PRODUCT_ORDER_FOR_STOCK_FAIL";

export const GET_ALL_PRODUCTS_LIST_REQUEST =
  "ADD_PRODUCT_ORDER_FOR_STOCK_REQUEST";
export const GET_ALL_PRODUCTS_LIST_SUCCESS =
  "ADD_PRODUCT_ORDER_FOR_STOCK_SUCCESS";
export const GET_ALL_PRODUCTS_LIST_FAIL = "ADD_PRODUCT_ORDER_FOR_STOCK_FAIL";

export const EDIT_PRODUCT_LIST_REQUEST = "EDIT_PRODUCT_LIST_REQUEST";
export const EDIT_PRODUCT_LIST_SUCCESS = "EDIT_PRODUCT_LIST_SUCCESS";
export const EDIT_PRODUCT_LIST_FAIL = "EDIT_PRODUCT_LIST_FAIL";

export const GET_ER_MANAGEMENT_DATA_REQUEST = "GET_ER_MANAGEMENT_DATA_REQUEST";
export const GET_ER_MANAGEMENT_DATA_SUCCESS = "GET_ER_MANAGEMENT_DATA_SUCCESS";
export const GET_ER_MANAGEMENT_DATA_FAIL = "GET_ER_MANAGEMENT_DATA_FAIL";

export const GET_ORDER_SYSTEM_CHINA_REQUEST = "GET_ORDER_SYSTEM_CHINA_REQUEST";
export const GET_ORDER_SYSTEM_CHINA_SUCCESS = "GET_ORDER_SYSTEM_CHINA_SUCCESS";
export const GET_ORDER_SYSTEM_CHINA_FAIL = "GET_ORDER_SYSTEM_CHINA_FAIL";

export const GET_COMPLETED_ORDER_SYSTEM_CHINA_REQUEST =
  "GET_COMPLETED_ORDER_SYSTEM_CHINA_REQUEST";
export const GET_COMPLETED_ORDER_SYSTEM_CHINA_SUCCESS =
  "GET_COMPLETED_ORDER_SYSTEM_CHINA_SUCCESS";
export const GET_COMPLETED_ORDER_SYSTEM_CHINA_FAIL =
  "GET_COMPLETED_ORDER_SYSTEM_CHINA_FAIL";

export const GET_COMPLETED_ORDER_DETAILS_SYSTEM_CHINA_REQUEST =
  "GET_COMPLETED_ORDER_DETAILS_SYSTEM_CHINA_REQUEST";
export const GET_COMPLETED_ORDER_DETAILS_SYSTEM_CHINA_SUCCESS =
  "GET_COMPLETED_ORDER_DETAILS_SYSTEM_CHINA_SUCCESS";
export const GET_COMPLETED_ORDER_DETAILS_SYSTEM_CHINA_FAIL =
  "GET_COMPLETED_ORDER_DETAILS_SYSTEM_CHINA_FAIL";

export const GET_ON_HOLD_ORDER_DETAILS_SYSTEM_CHINA_REQUEST =
  "GET_ON_HOLD_ORDER_DETAILS_SYSTEM_CHINA_REQUEST";
export const GET_ON_HOLD_ORDER_DETAILS_SYSTEM_CHINA_SUCCESS =
  "GET_ON_HOLD_ORDER_DETAILS_SYSTEM_CHINA_SUCCESS";
export const GET_ON_HOLD_ORDER_DETAILS_SYSTEM_CHINA_FAIL =
  "GET_ON_HOLD_ORDER_DETAILS_SYSTEM_CHINA_FAIL";

export const GET_ORDER_DETAILS_CHINA_REQUEST =
  "GET_ORDER_DETAILS_CHINA_REQUEST";
export const GET_ORDER_DETAILS_CHINA_SUCCESS =
  "GET_ORDER_DETAILS_CHINA_SUCCESS";
export const GET_ORDER_DETAILS_CHINA_FAIL = "GET_ORDER_DETAILS_CHINA_FAIL";

export const UPLOAD_ATTACH_FILE_CHINA_REQUEST =
  "UPLOAD_ATTACH_FILE_CHINA_REQUEST";
export const UPLOAD_ATTACH_FILE_CHINA_SUCCESS =
  "UPLOAD_ATTACH_FILE_CHINA_SUCCESS";
export const UPLOAD_ATTACH_FILE_CHINA_FAIL = "UPLOAD_ATTACH_FILE_CHINA_FAIL";

export const UPLOAD_OVERALL_ATTACH_FILE_CHINA_REQUEST =
  "UPLOAD_OVERALL_ATTACH_FILE_CHINA_REQUEST";
export const UPLOAD_OVERALL_ATTACH_FILE_CHINA_SUCCESS =
  "UPLOAD_OVERALL_ATTACH_FILE_CHINA_SUCCESS";
export const UPLOAD_OVERALL_ATTACH_FILE_CHINA_FAIL =
  "UPLOAD_OVERALL_ATTACH_FILE_CHINA_FAIL";

export const INSERT_ORDER_PICKUP_CHINA_REQUEST =
  "INSERT_ORDER_PICKUP_CHINA_REQUEST";
export const INSERT_ORDER_PICKUP_CHINA_SUCCESS =
  "INSERT_ORDER_PICKUP_CHINA_SUCCESS";
export const INSERT_ORDER_PICKUP_CHINA_FAIL = "INSERT_ORDER_PICKUP_CHINA_FAIL";

export const INSERT_ORDER_PICKUP_CANCEL_CHINA_REQUEST =
  "INSERT_ORDER_PICKUP_CANCEL_CHINA_REQUEST";
export const INSERT_ORDER_PICKUP_CANCEL_CHINA_SUCCESS =
  "INSERT_ORDER_PICKUP_CANCEL_CHINA_SUCCESS";
export const INSERT_ORDER_PICKUP_CANCEL_CHINA_FAIL =
  "INSERT_ORDER_PICKUP_CANCEL_CHINA_FAIL";

export const CUSTOM_ORDER_FINISH_CHINA_REQUEST =
  "CUSTOM_ORDER_FINISH_CHINA_REQUEST";
export const CUSTOM_ORDER_FINISH_CHINA_SUCCESS =
  "CUSTOM_ORDER_FINISH_CHINA_SUCCESS";
export const CUSTOM_ORDER_FINISH_CHINA_FAIL = "CUSTOM_ORDER_FINISH_CHINA_FAIL";

export const CUSTOM_ORDER_ON_HOLD_CHINA_REQUEST =
  "CUSTOM_ORDER_ON_HOLD_CHINA_REQUEST";
export const CUSTOM_ORDER_ON_HOLD_CHINA_SUCCESS =
  "CUSTOM_ORDER_ON_HOLD_CHINA_SUCCESS";
export const CUSTOM_ORDER_ON_HOLD_CHINA_FAIL =
  "CUSTOM_ORDER_ON_HOLD_CHINA_FAIL";

export const CUSTOM_ORDER_ON_HOLD_FINISH_CHINA_REQUEST =
  "CUSTOM_ORDER_ON_HOLD_FINISH_CHINA_REQUEST";
export const CUSTOM_ORDER_ON_HOLD_FINISH_CHINA_SUCCESS =
  "CUSTOM_ORDER_ON_HOLD_FINISH_CHINA_SUCCESS";
export const CUSTOM_ORDER_ON_HOLD_FINISH_CHINA_FAIL =
  "CUSTOM_ORDER_ON_HOLD_FINISH_CHINA_FAIL";

export const ADD_MESSAGE_CHINA_REQUEST = "ADD_MESSAGE_CHINA_REQUEST";
export const ADD_MESSAGE_CHINA_SUCCESS = "ADD_MESSAGE_CHINA_SUCCESS";
export const ADD_MESSAGE_CHINA_FAIL = "ADD_MESSAGE_CHINA_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
